@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/default.less';

html,
body {
    font-family: 'Poppins', serif;
    letter-spacing: 0.02em;
    font-weight: 400;
    overscroll-behavior: none;
}

@keyframes flip-animation {
    0% {
        transform: rotateY(0);
    }

    100% {
        transform: rotateY(180deg);
    }
}

/* Customize scrollbar style */

::-webkit-scrollbar {
    height: 4px;
    width: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.small-scrollbar {
    &::-webkit-scrollbar {
        height: 4px;
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
        border-radius: 2px;
    }
}

.ant-btn > .anticon {
    svg {
        vertical-align: -0.125em;
    }
}

.overflow-ellipsis-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
}
.overflow-ellipsis-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
}

.gradient-card {
    background: linear-gradient(180deg, rgba(28, 187, 241, 0.3) -86.28%, rgba(28, 187, 241, 0) 114.63%);
}

.container {
    max-width: 1638px;
}

/**
    Antd Customization
*/

// Button
.ant-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.ant-btn:not(.ant-btn-text):not(.ant-btn-primary) {
    border: 1px solid @primary-color;
}

.socialscan-btn-primary,
.ant-btn-primary {
    transition: none;
    border: none;
    box-shadow: none;
    background: #000;
    text-shadow: none;
}
.socialscan-btn-primary:hover,
.ant-btn-primary:hover {
    background: rgba(0, 0, 0, 0.9);
    border: none;
    box-shadow: none;
}
.socialscan-btn-primary:focus,
.ant-btn-primary:focus {
    background: rgba(0, 0, 0, 0.9);
    border: none;
    box-shadow: none;
}
.socialscan-btn-primary:active,
.ant-btn-primary:active {
    border: none;
    box-shadow: none;
    background: rgba(0, 0, 0, 0.9);
}
// .socialscan-btn-primary[disabled],
// .socialscan-btn-primary[disabled]:hover,
// .socialscan-btn-primary.disabled,
// .socialscan-btn-primary.disabled:hover,
// .ant-btn-primary[disabled],
// .ant-btn-primary[disabled]:hover {
//     border: none;
//     box-shadow: none;
//     background: rgba(0, 0, 0, 0.9);
//     position: relative;
//     opacity: 0.5;
// }

.ant-menu-title-content {
    width: 100%;
}

// Tabs
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: none;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
    border-radius: 0;
}
.ant-tabs-tab {
    font-size: 14px;
    color: #999999;
}
.ant-tabs-tab-active {
    font-weight: 600;
    color: #000;
}

.dark-modal {
    .ant-modal {
        .ant-modal-content {
            background-color: #0e0e0e;
        }
    }

    &.ant-popover {
        .ant-popover-arrow-content::before {
            background-color: #222;
        }
        .ant-popover-inner {
            background-color: #222;

            .ant-popover-inner-content {
                color: #fff;
            }
        }
    }
}

// Modal
.ant-modal {
    @media screen and (max-width: 768px) {
        max-width: calc(100vw - 30px);
    }

    .ant-modal-content {
        @apply p-4 md:p-8;
        border-radius: 24px;

        &.no-bg {
            background-color: transparent;
        }

        .ant-modal-close {
            @apply absolute left-1/2 right-auto top-auto bottom-0 -translate-x-1/2 translate-y-full;
            transform: translateY(200%);
        }

        .ant-modal-close-x {
            @apply text-white text-opacity-90 text-base transition-colors;
            @apply w-8 h-8;
            @apply flex-center-center;
            @apply rounded-full border-2 border-white border-opacity-90;

            &:hover {
                @apply text-w3w border-w3w;
            }

            @media screen and (max-width: 768px) {
                @apply w-6 h-6 text-opacity-80 border-opacity-80;
            }
        }
    }

    .ant-modal-header {
        @apply bg-transparent;
        @apply border-none;
        @apply p-0 pb-8;

        .ant-modal-title {
            @apply text-xl font-semibold text-center;
        }
    }

    .ant-modal-body {
        @apply p-0;
        max-height: 50vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
            height: 4px;
            width: 2px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.15);
            border-radius: 2px;
        }
    }

    .ant-modal-footer {
        @apply flex-center-center gap-x-5 w-full;
        @apply border-none;
        @apply p-0 pt-6;

        button {
            @apply flex-1;
            // max-width: 50%;

            @media screen and (max-width: 768px) {
                height: 36px;
                font-size: 14px;
                font-weight: 600;
            }

            @media screen and (min-width: 769px) {
                font-size: 16px;
                font-weight: 600;
            }
        }

        .ant-btn-primary > span {
            @apply colorful-text;
        }
    }
}

// Radio
.ant-radio-button-wrapper {
    font-size: 12px;
    color: #999;
    border-color: #0000001a !important;
    height: 32px;
    line-height: 30px;

    &::before {
        width: 0px !important;
    }

    &:not(:last-child) {
        border-right-width: 0 !important;
    }

    &:first-child {
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-radius: 0 4px 4px 0;
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background-color: #f7f7f7;
        font-weight: 500;
        color: #000;
        box-shadow: none;
    }
}

// Drawer
.ant-drawer {
    &.ant-drawer-bottom {
        .ant-drawer-content {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            .ant-drawer-header {
                .ant-drawer-title {
                    @apply text-center;
                }

                .ant-drawer-close {
                    top: -16px;
                    @apply absolute right-0 -translate-y-full;
                    @apply w-6 h-6;
                    @apply flex-center-center;
                    @apply rounded-full border-2 border-solid border-white border-opacity-90;

                    .anticon-close {
                        @apply text-white text-opacity-90 text-xs leading-1 transition-colors;

                        &:hover {
                            @apply text-w3w border-w3w;
                        }
                    }
                }
            }
        }
    }

    .ant-drawer-content-wrapper {
        @apply max-w-full;

        .ant-drawer-content {
            @apply overflow-visible;

            .ant-drawer-wrapper-body {
                .ant-drawer-header {
                    background-color: transparent;
                    @apply border-none;
                    @apply px-5 py-4;

                    .ant-drawer-title {
                        @apply text-lg;
                    }
                }
                .ant-drawer-body {
                    @media screen and(max-width:768px) {
                        padding: 10px 20px 20px;
                    }
                }
            }
        }
    }
}

.ant-message {
    .anticon {
        @apply top-0;
        @apply align-middle;
    }

    .ant-message-notice-content {
        @apply rounded-lg p-0;
    }

    .ant-message-success {
        background-color: #12be5133;
    }

    .ant-message-error {
        background-color: #ff462a33;
    }
}

.ant-tooltip {
    max-width: min(460px, 70vw);
    .ant-tooltip-inner {
        white-space: pre-line;
        @apply rounded-xl text-xs px-3;
    }
}

.ant-popover {
    max-width: 80vw;

    .ant-popover-inner {
        border-radius: 10px;
    }

    .ant-popover-title {
        padding: 8px 16px;
    }
}

// Message
.ant-message-custom-content {
    padding: 10px 16px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 4px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 1.3;

    .anticon {
        font-size: 24px;
    }

    // &.ant-message-success {
    //     background: #56735d;
    // }
    // &.ant-message-warning {
    //     background: #7f604e;
    // }
    // &.ant-message-error {
    //     background: #8d5a55;
    // }

    // &.ant-message-info {
    //     background: rgba(0, 0, 0, 0.3);
    // }
}

// Input
.customize-input.ant-input {
    @apply bg-form-bg;
    @apply h-12 rounded-xl text-base max-w-full;
}
.ant-input[disabled] {
    border: transparent;
}

// Select
.customize-select.ant-select {
    .ant-select-selector {
        @apply !h-12  max-w-full;
        @apply bg-form-bg;
        @apply h-12 rounded-xl text-base;
    }
}

// Avatar
.ant-avatar .anticon {
    vertical-align: 0.125em;
}

.ant-avatar-string {
    transform: scale(1) translateX(-50%);
}

// Pagination
.ant-pagination {
    @apply !my-4 md:!mt-10;

    .ant-pagination-item {
        @apply rounded-md;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        @apply !leading-1;
        button {
            @apply align-middle;
        }
    }

    .ant-pagination-item-link-icon.anticon-double-left,
    .ant-pagination-item-link-icon.anticon-double-right {
        @apply align-middle;
    }
}

// Checkbox
.ant-checkbox {
    top: 0.165em;
}

.ant-alert {
    padding: 12px 16px;

    &.ant-alert-info {
        .ant-alert-message {
            color: #2990ffe5;
        }
    }
}

.no-bg {
    .ant-popover-inner {
        background-color: transparent;
        box-shadow: none;
    }

    // Modal
    .ant-modal-content {
        background-color: transparent;
        box-shadow: none;
    }
}

.no-padding {
    .ant-popover-inner-content {
        padding: 0;
    }

    .ant-popover-arrow {
        border-color: transparent !important;
    }

    // Modal
    .ant-modal-body {
        padding: 0;
    }

    // Drawer
    .ant-drawer-body {
        padding: 0;
    }
}

.ant-select-dropdown,
.ant-dropdown-menu {
    background-color: #fff;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.origin-css {
    table,
    th,
    td {
        border: 1px solid #444;
        border-collapse: collapse;
    }
    table {
        display: table;
        border-spacing: 2px;
        border-color: #444;
    }

    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    tbody {
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit;
    }

    tfoot {
        display: table-footer-group;
        vertical-align: middle;
        border-color: inherit;
    }

    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }

    td,
    th {
        display: table-cell;
        vertical-align: inherit;
    }

    th {
        font-weight: bold;
        text-align: -internal-center;
    }

    td {
        text-align: inherit;
    }

    h1 {
        display: block;
        font-size: 2em;
        margin-top: 0.67em;
        margin-bottom: 0.67em;
        margin-left: 0;
        margin-right: 0;
        font-weight: bold;
    }

    h4 {
        display: block;
        font-size: 1.33em;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        font-weight: bold;
    }

    h5 {
        display: block;
        font-size: 1.17em;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        font-weight: bold;
    }

    p {
        display: block;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
    }

    br {
        display: block;
        content: '';
        margin-top: 1em;
    }

    a {
        color: blue;
        text-decoration: underline;
        cursor: pointer;
    }

    strong {
        font-weight: bold;
    }

    ul {
        display: block;
        list-style-type: disc; /* 这是默认样式，你也可以设置为其他值，比如 circle、square、none 等 */
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px; /* 这给列表项前面的标记（通常是小黑点）提供了空间 */
    }

    li {
        display: list-item;
        text-align: -webkit-match-parent; /* 浏览器会试图将元素的 text-align 属性设置成与父元素相同的值 */
    }
}

html[style*='top:'].scroll-lock.cdk-global-scrollblock {
    position: static !important;
    width: initial !important;
    overflow-y: inherit !important;
    top: 0 !important;

    .ant-modal-wrap {
        overflow: hidden !important;
    }
}

.ant-menu {
    &:not(.ant-menu-inline) {
        &.global-header-submenu {
            margin-top: 10px;
            padding: 10px;
        }
    }

    &.global-header-submenu {
        background-color: #ffffff;
        border-radius: 10px;
        // min-width: 220px;
        min-width: max-content;
        color: black;

        .ant-menu-item,
        .ant-menu-submenu {
            font-size: 16px;
            transition: border-radius 0s ease-in-out;
            min-width: fit-content;

            &:hover,
            &:active {
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 10px;
            }
        }
    }
}

.ant-image-preview-img-wrapper img {
    display: inline-block;
}

.driver-popover.ss-driver-popover {
    background-color: #0b2b37;
    color: rgba(255, 255, 255, 0.9);
    max-width: 500px;
    @apply rounded-3xl p-8;

    .driver-popover-arrow {
        display: none;
    }

    #driver-popover-title {
        @apply text-2xl text-center;
    }

    #driver-popover-description {
        @apply mt-5;
    }

    .driver-popover-footer {
        @apply !flex mt-6;

        .driver-popover-navigation-btns {
            @apply flex justify-center;

            .driver-popover-prev-btn {
                @apply hidden;
            }

            .driver-popover-next-btn {
                @apply flex-center-center  text-white text-opacity-90;
                @apply text-base font-semibold rounded-lg;
                @apply hover:opacity-80;

                transition: none;
                border: none;
                width: 200px;
                height: 56px;
                box-shadow: inset 0 0 0 2px #ffffff66;
                background: linear-gradient(90deg, @primary-color -4.12%, #35d0cd 104.12%);
                text-shadow: none;
            }
        }
    }
}

w3m-modal {
    z-index: 1001;
}

.ant-btn:not(.ant-btn-text):not(.ant-btn-primary).icon-btn {
    padding: 0.3125rem !important;
    line-height: 1 !important;
    border-radius: 0.25rem;
    background-color: #f7f7f7;
    font-size: 1rem;
    color: #000;
    border: 1px solid #f7f7f7;

    &:hover {
        background-color: #fff;
        border-color: #000;
    }
    > * {
        font-size: 14px;
    }
}
